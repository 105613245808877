import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "bootstrap/dist/css/bootstrap.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faSearch,
  faKey,
  faXmark,
  faPenToSquare,
  faPlus,
  faSquarePlus,
  faGear,
  faArrowLeft,
  faShareNodes,
  faBold,
  faTrash,
  faItalic,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitterSquare,
  faFacebookSquare,
  faGooglePlusSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faRightFromBracket,
  faTwitterSquare,
  faFacebookSquare,
  faGooglePlusSquare,
  faUser,
  faSearch,
  faKey,
  faTrash,
  faXmark,
  faPenToSquare,
  faSquarePlus,
  faPlus,
  faGear,
  faBold,
  faItalic,
  faInstagramSquare,
  faArrowLeft,
  faShareNodes
);

const app = createApp(App);
const head = createHead();
app.use(head);
app.use(store);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.mount("#app");

import "bootstrap/dist/js/bootstrap.js";

import { createStore } from "vuex";
import { db, storageF } from "@/db";
import {
  uploadBytes,
  ref,
  getDownloadURL,
  uploadString,
} from "firebase/storage";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  addDoc,
  getDoc,
  orderBy,
  where,
  limit,
  query,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import sites from "@/assets/files/text.json";
import { auth } from "../db";
export default createStore({
  state: {
    classements: [],
    isAuthentificate: false,
    user: {
      loggedIn: false,
      data: null,
    },
    menus: {
      basketball: [
        {
          type: 0,
          title: "Accueil",
          link: "/basketball",
        },
        {
          type: 0,
          title: "Actualités",
          link: "/basketball/actualite",
        },
        /*{
          type: 0,
          title: "Club",
          link: "/basketball/club",
        },*/
        {
          type: 0,
          title: "Calendrier/Resultat",
          link: "/basketball/calendrierresultat",
        },
        {
          type: 0,
          title: "Nous contacter",
          link: "/basketball/contact",
        },
      ],
      football: [
        {
          type: 0,
          title: "Accueil",
          link: "/football",
        },
        {
          type: 0,
          title: "Actualités",
          link: "/football/actualite",
        },
        {
          type: 0,
          title: "Club",
          link: "/football/club",
        },
        {
          type: 0,
          title: "Calendrier/Resultat",
          link: "/football/calendrierresultat",
        },
        {
          type: 0,
          title: "Nous contacter",
          link: "/football/contact",
        },
      ],
    },
    classement: [],
  },
  getters: {
    deleteItem: (state) => async (collectionName, id) => {
      const docRef = doc(db, collectionName, id);
      return await deleteDoc(docRef);
    },
    isAuthentificate: (state) => () => {
      const isAuthentificate = localStorage.getItem("isAuthentificate");
      return isAuthentificate === "true";
    },
    setAuthentification: (state) => (value) => {
      localStorage.setItem("isAuthentificate", value);
    },
    connexion: (state) => async (username, password) => {
      return await signInWithEmailAndPassword(auth, username, password);
    },
    logout: (state) => async () => {
      return await signOut(auth);
    },
    user(state) {
      return state.user;
    },
    getFirestoreId: (state) => async (collectionName) => {
      const docRef = await doc(collection(db, collectionName));
      return docRef.id;
    },
    updateFirestorDocument: (state) => async (collection, id, data) => {
      const docref = doc(db, collection, id);
      await updateDoc(docref, data);
    },
    newFirestorDocument: (state) => async (collectionName, data) => {
      return await addDoc(collection(db, collectionName), data);
    },
    setFirestorDocument: (state) => async (collectionName, id, data) => {
      const docRef = doc(db, collectionName, id);
      return await setDoc(docRef, data);
    },
    uploadStorageImage: (state) => async (file, filename) => {
      const storageRef = ref(storageF, filename);

      // 'file' comes from the Blob or File API
      uploadString(storageRef, file, "data_url").then((snapshot) => {
        console.log(snapshot);
      });
    },

    uploadFile: (state) => async (file, filename) => {
      const storageRef = ref(storageF, filename);
      const newMetadata = {
        cacheControl: "public",
      };
      await uploadBytes(storageRef, file);

      return await getDownloadURL(storageRef);
    },

    getArticle: (state) => async (sport, id) => {
      const articleRef = doc(db, `${sport}_articles`, id);
      const article = await getDoc(articleRef);
      if (!article.exists) {
        return {};
      } else {
        return article.data();
      }
    },
    getClassement: (state) => async (sport) => {
      const classementRef = collection(db, `${sport}_classement`);
      const q = query(classementRef, orderBy("id"));
      const classementSnap = await getDocs(q);
      const classement = classementSnap.docs.reduce((groups, item) => {
        groups[item.id] = item.data();
        return groups;
      }, {});
      return classement;
    },
    getSiteText: (state) => async (sport) => {
      return sites.find((x) => x.site === sport);
    },
    getLastGame: (state) => async (sport) => {
      const gamesRef = collection(db, `${sport}_matches`);

      const q = query(
        gamesRef,
        where("date", "<=", new Date()),
        orderBy("date", "asc")
      );
      const querySnapshot = await getDocs(q);

      return (
        [
          ...new Map(
            querySnapshot.docs
              .map((d) => {
                let obj = d.data();
                obj.date = obj.date.toDate();
                obj.id = d.id;
                return obj;
              })
              .filter((val) => val.team1.score > 0 || val.team2.score > 0)
              .map((item) => [item.championnat, item])
          ),
        ] ?? []
      );
    },
    getNextGame: (state) => async (sport) => {
      const gamesRef = collection(db, `${sport}_matches`);

      const q = query(gamesRef, where("date", ">=", new Date()));
      const querySnapshot = await getDocs(q);
      return (
        querySnapshot.docs
          .map((d) => {
            let obj = d.data();
            obj.date = obj.date.toDate();
            obj.id = d.id;
            return obj;
          })
          .filter((val) => val.team1.score == 0 && val.team2.score == 0)[0] ??
        {}
      );
    },
    getLatestNews: (state) => async (sport, max) => {
      const articlesRef = collection(db, `${sport}_articles`);
      const q = query(articlesRef, orderBy("date", "desc"), limit(max));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((d) => {
        let obj = d.data();
        obj.id = d.id;
        obj.date = obj.date.toDate();
        return obj;
      });
    },
    getNews: (state) => async (sport) => {
      const articlesRef = collection(db, `${sport}_articles`);
      const q = query(articlesRef, orderBy("date", "desc"));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((d) => {
        let obj = d.data();
        obj.id = d.id;
        obj.date = obj.date.toDate();
        return obj;
      });
    },
    getAllGames: (state) => async (sport) => {
      const gamesRef = collection(db, `${sport}_matches`);
      const q = query(gamesRef, orderBy("date", "desc"));
      const querySnapshot = await getDocs(q);
      return (
        querySnapshot.docs.map((d) => {
          let obj = d.data();
          obj.id = d.id;
          if (typeof obj.date.toDate === "function") {
            obj.date = obj.date.toDate();
          } else {
            obj.date = undefined;
          }
          return obj;
        }) ?? []
      );
    },
    getMatch: (state) => async (sport, id) => {
      const matchRef = doc(db, `${sport}_matches`, id);
      const match = await getDoc(matchRef);
      if (!match.exists) {
        return {};
      } else {
        let result = match.data();
        result.id = match.id;
        if (result.liens) {
          for (let game of result.liens) {
            console.log(game);
            game.date = game.date.toDate();
          }
        } else {
          result.liens = [];
        }

        return result;
      }
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
        });
      } else {
        commit("SET_USER", null);
      }
    },
  },
  modules: {},
});
